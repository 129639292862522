import React, { useState, useRef, useEffect } from "react";
import { Button, Container, Typography, Box } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import WaveSurfer from "wavesurfer.js";
import { useRecoilState } from "recoil";
import { currentFontSize } from "./atom.js";

const App = () => {
  const [isRecording, setIsRecording] = useState(false);
  const waveSurferRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recognitionRef = useRef(null);
  const audioChunks = useRef([]);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const [amplitude, setAmplitude] = useState(0);
  const [finalTranscriptHTML, setFinalTranscriptHTML] = useState("");
  const [interimTranscriptHTML, setInterimTranscriptHTML] = useState("");
  const [fontSize, setFontSize] = useRecoilState(currentFontSize);
  const maxFontSizeRef = useRef(0);
  const canvasRef = useRef(null);
  const barChartCanvasRef = useRef(null);

  useEffect(() => {
    if (!waveSurferRef.current) {
      waveSurferRef.current = WaveSurfer.create({
        container: "#waveform",
        waveColor: "#87CEEB",
        progressColor: "#ADD8E6",
        backgroundColor: "black",
      });
    }
  }, []);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        analyser.fftSize = 1024;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        audioContextRef.current = audioContext;
        analyserRef.current = analyser;
        dataArrayRef.current = dataArray;

        const drawSpectrogram = () => {
          const canvas = canvasRef.current;
          if (canvas) {
            const canvasCtx = canvas.getContext("2d");
            const width = canvas.width;
            const height = canvas.height;
            const sliceWidth = 2;

            analyser.getByteFrequencyData(dataArray);

            const imageData = canvasCtx.getImageData(
              sliceWidth,
              0,
              width - sliceWidth,
              height
            );
            canvasCtx.putImageData(imageData, 0, 0);

            canvasCtx.clearRect(width - sliceWidth, 0, sliceWidth, height);
            for (let i = 0; i < bufferLength; i++) {
              const value = dataArray[i];
              const percent = value / 256;
              const y = Math.floor(percent * height);
              const color = `rgb(135, ${value}, 235)`;
              canvasCtx.fillStyle = color;
              canvasCtx.fillRect(width - sliceWidth, height - y, sliceWidth, y);
            }
          }
          requestAnimationFrame(drawSpectrogram);
        };

        const drawBarChart = () => {
          const canvas = barChartCanvasRef.current;
          if (canvas) {
            const canvasCtx = canvas.getContext("2d");
            const width = canvas.width;
            const height = canvas.height;
            const barWidth = width / bufferLength;

            analyser.getByteFrequencyData(dataArray);

            canvasCtx.clearRect(0, 0, width, height);

            for (let i = 0; i < bufferLength; i++) {
              const value = dataArray[i];
              const percent = value / 256;
              const barHeight = percent * height;
              const color = `rgb(135, ${value}, 235)`;
              canvasCtx.fillStyle = color;
              canvasCtx.fillRect(
                i * barWidth,
                height - barHeight,
                barWidth,
                barHeight
              );
            }
          }
          requestAnimationFrame(drawBarChart);
        };

        const updateAmplitude = () => {
          analyser.getByteTimeDomainData(dataArray);
          const maxAmplitude = Math.max(
            ...dataArray.map((val) => Math.abs(val - 128))
          );
          setAmplitude(maxAmplitude / 128);
          requestAnimationFrame(updateAmplitude);
        };

        drawSpectrogram();
        drawBarChart();
        updateAmplitude();
      })
      .catch((err) => {
        console.error("Error accessing audio:", err);
      });
  }, []);

  const toggleRecording = () => {
    if (isRecording) {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      setIsRecording(false);
    } else {
      audioChunks.current = [];
      maxFontSizeRef.current = 0;

      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.lang = "ja-JP";
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let interimText = "";
        let finalText = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcriptResult = event.results[i][0].transcript;

          analyserRef.current.getByteTimeDomainData(dataArrayRef.current);
          const maxAmplitude = Math.max(
            ...dataArrayRef.current.map((val) => Math.abs(val - 128))
          );

          const curryFontSize = 16 + (maxAmplitude / 64) * 32;
          setFontSize(curryFontSize);

          if (curryFontSize > maxFontSizeRef.current) {
            maxFontSizeRef.current = curryFontSize;
          }

          if (event.results[i].isFinal) {
            finalText += `<span style="font-size: ${maxFontSizeRef.current}px; color: #FFDAB9;">${transcriptResult}</span>`;
            maxFontSizeRef.current = 0;
          } else {
            interimText += `<span style="font-size: ${curryFontSize}px; color: #FFDAB9;">${transcriptResult}</span>`;
          }
        }

        setInterimTranscriptHTML(interimText);
        setFinalTranscriptHTML((prev) => prev + finalText);
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
      };

      recognitionRef.current = recognition;
      recognition.start();

      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          waveSurferRef.current.load(audioUrl);
        };

        mediaRecorder.start();
        setIsRecording(true);
      });
    }
  };

  useEffect(() => {
    if (isRecording) {
      console.log("Recording started");
    } else {
      console.log("Recording stopped");
    }
  }, [isRecording]);

  return (
    <Container maxWidth={false} className="container-root">
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: "100%", alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ color: "#87CEEB" }}>
          App Title
        </Typography>
      </Box>
      <Box
        display="flex"
        sx={{
          width: "100%",
          padding: "3%",
          overflowX: "hidden",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ width: "60%", marginRight: "2%" }}>
          <Typography variant="h6" sx={{ color: "#87CEEB" }}>
            Transcription:
          </Typography>
          <Box
            sx={{
              height: "calc(100vh - 200px)", // 画面の高さに応じて調整
              overflowY: "auto",
              border: "0.1em solid #87CEEB",
              padding: "1%",
              marginBottom: "0.5%",
              width: "100%", // 幅を100%にして横幅いっぱいに広げる
            }}
          >
            <Typography
              variant="body1"
              style={{ transition: "font-size 0.2s", color: "#FFDAB9" }}
              dangerouslySetInnerHTML={{
                __html: finalTranscriptHTML + interimTranscriptHTML,
              }}
            />
          </Box>
        </Box>
        <Box sx={{ width: "38%", display: "flex", flexDirection: "column" }}>
          <Box sx={{ marginBottom: "5%", width: "100%" }}>
            <Typography variant="h6" sx={{ color: "#87CEEB" }}>
              Spectrogram:
            </Typography>
            <canvas
              ref={canvasRef}
              style={{
                width: "100%", // 幅を100%にして横幅いっぱいに広げる
                height: "150px",
                backgroundColor: "black",
                border: "0.05em solid #87CEEB",
              }}
            />
            <Box sx={{ marginBottom: "5%", width: "100%" }}>
              <Typography variant="h6" sx={{ color: "#87CEEB" }}>
                Spectrum:
              </Typography>
              <canvas
                ref={barChartCanvasRef}
                style={{
                  width: "100%", // 幅を100%にして横幅いっぱいに広げる
                  height: "150px",
                  backgroundColor: "black",
                  border: "0.05em solid #87CEEB",
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "5%", width: "100%" }}>
              <Typography variant="h6" sx={{ color: "#87CEEB" }}>
                Sampling:
              </Typography>
              <div
                id="waveform"
                style={{
                  width: "100%", // 幅を100%にして横幅いっぱいに広げる
                  height: "150px",
                  border: "0.05em solid #87CEEB",
                }}
              ></div>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="footer-box">
        <Box className="footer-item">
          <Typography variant="h6" className="responsive-text">
            Font Size:
          </Typography>
          <Typography variant="body1" className="responsive-text">
            {fontSize.toFixed(2)} px
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={toggleRecording}
          className="footer-button"
        >
          {isRecording ? <PauseIcon /> : <PlayArrowIcon />}
        </Button>
        <Box className="footer-item">
          <Typography variant="h6" className="responsive-text">
            Amplitude:
          </Typography>
          <Typography variant="body1" className="responsive-text">
            {amplitude.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default App;
